import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { RegExValidation } from '../constants/regex-validation.constants';

@Directive({
  selector: '[numeric-field]'
})
export class NumericFieldDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event: any) {
    const initalValue = this.el.nativeElement.value;

    this.el.nativeElement.value = initalValue.replace(RegExValidation.numeric, '');
    if ( initalValue !== this.el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
